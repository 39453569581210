.btn{
    background:#5428ff;
    color:#fff;
    /* border:none; */
    position:relative;
    /* height:60px; */
    border-radius: 10px;
    font-size:1.2em;
    /* padding:0 2em; */
    width: 30%;
    display: flex;
    cursor:pointer;
    transition:800ms ease all;
    outline:none;
  }
  .btn:hover{
    background:#fff;
    color:#111c44;
  }
  .btn:before,.btn:after{
    content:'';
    position:absolute;
    top:0;
    right:0;
    height:2px;
    width:0;
    border-radius: 10px;
    background: #5428ff;
    transition:400ms ease all;
  }
  .btn:after{
    right:inherit;
    top:inherit;
    left:0;
    bottom:0;
  }
  .btn:hover:before,.btn:hover:after{
    width:100%;
    transition:800ms ease all;
  }
  